html,
body {
    height: 100%;
}

html {
    line-height: normal;
}

[id='app'] {
    background: #fff;
    height: 100%;
}

.main-header {
    height: 3.5rem;
    background: #fff;
}

.main-footer {
    padding: 1.5rem 1rem;
}

@media screen and (min-width: 75em) {
    .main-layout {
        background: url('../media/backgrounds/background-right-light.svg') no-repeat top right /
            auto 100%;
    }
}
